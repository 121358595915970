import './css/style.scss';
import Vue from 'vue';
import App from './vue/App.vue';
import FooterList from './vue/FooterList.vue';
import Vuelidate from 'vuelidate';

Vue.use(Vuelidate);

window.axios = require('axios');

const app = new Vue({
  el: '#app',
  render: h => h(App)
});

const footerList = new Vue({
  el: '#footerList',
  render: h => h(FooterList)
});
