<template>
	<ul class="list">
		<li v-for="item in items" class="list__item">
			<img :src="getSrc(item.icon)" alt=''>
			<slot>
				<span>{{ item.text }}</span>
			</slot>
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		items: Array
	},
	methods: {
		getSrc(src) {
			return require("../../../assets/" + src);
		}
	},
}
</script>
