<template>
	<div class="credit-form__input-group">
		<label class="credit-form__label">{{ label }}</label>
		<select :label="label" v-model="term" @input="setSelected" class="credit-form__input" required>
			<option v-for="option in options" :value="option">
				{{ option.quantity}} {{option.unit}}
			</option>
		</select>
		{{ term }}
	</div>
</template>

<script>
export default {
	props: {
		name: String,
		label: String,
		required: {
			type: Boolean,
			default: false
		},
		options: {
			type: Array,
		}
	},
	data() {
		return {
			term: ''
		}
	},
	methods: {
		setSelected() {
			this.$emit('input', this.term)
		}
	}
}
</script>
