<template>
	<div class="list-container">
		<list :items="offers" class="list list--images"><span></span></list>
	</div>
</template>

<script>

import List from './components/List.vue';

export default {
	components: {
		List
	},
	data () {
		return {
			offers: [
				{
					icon: 'static/offers/tinkoff.png'
				},
				{
					icon: 'static/offers/sovcom.png'
				},
				{
					icon: 'static/offers/creditctar.png'
				},
				{
					icon: 'static/offers/vostochniy.png'
				},
				{
					icon: 'static/offers/creditkasa.png'
				},
				{
					icon: 'static/offers/ipb.png'
				},
				{
					icon: 'static/offers/fastmoney.png'
				},
				{
					icon: 'static/offers/homecred.png'
				},
				{
					icon: 'static/offers/uralsibRR.png'
				},
				{
					icon: 'static/offers/ligamoney.png'
				},
				{
					icon: 'static/offers/uralsib.png'
				},
				{
					icon: 'static/offers/kredito24.png'
				},
				{
					icon: 'static/offers/banando.png'
				},
				{
					icon: 'static/offers/moneyman.png'
				},
				{
					icon: 'static/offers/smartcredit.png'
				},
				{
					icon: 'static/offers/turbozaim.png'
				},
				{
					icon: 'static/offers/visame.png'
				},
				{
					icon: 'static/offers/skb-bank1.png'
				},
				{
					icon: 'static/offers/sms-finance.png'
				},
				{
					icon: 'static/offers/creditplus.png'
				},
				{
					icon: 'static/offers/ezaem.png'
				},
				{
					icon: 'static/offers/lime.png'
				},
				{
					icon: 'static/offers/webbankir.png'
				},
				{
					icon: 'static/offers/ekapusta.png'
				},
				{
					icon: 'static/offers/zaimer.png'
				},
				{
					icon: 'static/offers/pochta-bank.png'
				},
				{
					icon: 'static/offers/renessans.png'
				},
				{
					icon: 'static/offers/skb-bank2.png'
				}
			]
		}
	},
	methods: {
		getSrc(src) {
			return require("../../assets/" + src);
		}
	},
}
</script>
