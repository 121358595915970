<template>
    <div>
        <form class="credit-form" method="post" @submit.prevent="submitForm">
            <div class="container">
                <h3 class="credit-form__label">Требуемая сумма кредита</h3>
                <div class="credit-form__range">
                    <div class="range">
                        <range-slider
                                class="slider"
                                min="10000"
                                max="3000000"
                                step="10000"
                                v-model="form.amount">
                            <template v-slot:knob>
                                <span class="range-slider-knob--info">{{form.amount}} p.</span>
                            </template>
                        </range-slider>
                    </div>
                    <span class="range__start">10 000 руб.</span>
                    <span class="range__end">3 000 000 руб.</span>
                </div>

                <div class="credit-form__input-wrap credit-form__input-wrap--lg">
                    <div class="credit-form__input-group">
                        <label for="summ" class="credit-form__label">Сумма займа</label>
                        <input type="number" id="summ" placeholder="Сумма кредита" v-model="form.amount" min="10000"
                               class="credit-form__input" @input="setSum($event.target.value)" autocomplete="nope">
                        <div v-if="$v.form.amount.$error" class="invalid-feedback">Укажите сумму кредита. Сумма не должна быть меньше 10000р.</div>
                    </div>
                    <div class="credit-form__input-group">
                        <label class="credit-form__label">Срок займа (или кредита)</label>
                        <select v-model="form.term" class="credit-form__input" required>
                            <option v-for="term in terms" :value="term">
                                {{ term.quantity}} {{term.unit | translates(term, term.quantity)}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <fieldset>
                <div class="container">
                    <div class="credit-form__input-wrap">
                        <div class="credit-form__input-group">
                            <label for="lastName" class="credit-form__label">Фамилия</label>
                            <input type="text" id="lastName" placeholder="Фамилия" v-model="form.lastName" maxlength="25"
                                   class="credit-form__input" @blur="$v.form.lastName.$touch()" autocomplete="nope">
                            <div v-if="$v.form.lastName.$error" class="invalid-feedback">Фамилия может содержать только
                                русские буквы
                            </div>
                            <ul class="invalid-feedback" v-if="serverErrors">
                                <li v-for="(serverError, index) in serverErrors" :key="index">
                                    <p v-if="serverError.field ==='lastName'" >{{serverError.message}}</p>
                                </li>
                            </ul>
                        </div>

                        <div class="credit-form__input-group">
                            <label for="firstName" class="credit-form__label">Имя</label>
                            <input type="text" class="credit-form__input" placeholder="Имя" autocomplete="nope"
                                   v-model="form.firstName" id="firstName" name="firstName" maxlength="25"
                                   @blur="$v.form.firstName.$touch()">
                            <div v-if="$v.form.firstName.$error" class="invalid-feedback">Имя может содержать только
                                русские буквы
                            </div>
                            <ul class="invalid-feedback" v-if="serverErrors">
                                <li v-for="(serverError, index) in serverErrors" :key="index">
                                    <p v-if="serverError.field ==='firstName'" >{{serverError.message}}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="credit-form__input-group">
                            <label for="middleName" class="credit-form__label">Отчество</label>
                            <input type="text" class="credit-form__input" placeholder="Отчество" autocomplete="nope"
                                   v-model="form.middleName" maxlength="25" id="middleName" name="middleName"
                                   @blur="$v.form.middleName.$touch()">
                            <div v-if="$v.form.middleName.$error" class="invalid-feedback">Отчество может содержать
                                только русские буквы
                            </div>
                            <ul class="invalid-feedback" v-if="serverErrors">
                                <li v-for="(serverError, index) in serverErrors" :key="index">
                                    <p v-if="serverError.field ==='middleName'" >{{serverError.message}}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="credit-form__input-group credit-form__input-group--date">
                            <label for="birthDate" class="credit-form__label">Дата рождения</label>
                            <input type="text" class="credit-form__input" placeholder="ДД/ММ/ГГГГ"
                                   autocomplete="nope" v-model="form.birthDate" id="birthDate" name="birthDate"
                                   @blur="$v.form.birthDate.$touch()">
                            <div v-if="$v.form.birthDate.$error" class="invalid-feedback">Пожалуйста, введите
                                реальную дату в формате ДД/ММ/ГГГГ
                            </div>
                            <ul class="invalid-feedback" v-if="serverErrors">
                                <li v-for="(serverError, index) in serverErrors" :key="index">
                                    <p v-if="serverError.field ==='birthDate'" >{{serverError.message}}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="credit-form__input-group credit-form__input-group--dadata">
                            <label for="region" class="credit-form__label">Регион</label>
                            <VueSuggestions
                                    :model.sync="form.region"
                                    :coordinates.sync="coordinates"
                                    :value="''"
                                    class="form-control"
                                    :options="regionOptions"
                                    id="region"
                                    ref="region"
                                    autocomplete="nope"
                                    placeholder="Выберите регион проживания"
                            >
                            </VueSuggestions>
                        </div>

                        <div class="credit-form__input-group credit-form__input-group--dadata">
                            <label for="citi" class="credit-form__label">Город</label>
                            <VueSuggestions
                                    :model.sync="form.city"
                                    :coordinates.sync="coordinates"
                                    :value="''"
                                    class="form-control"
                                    :options="citiesOptions"
                                    id="citi"
                                    autocomplete="nope"
                                    placeholder="Выберите город проживания"
                            >
                            </VueSuggestions>
                        </div>
                        <div class="credit-form__input-group">
                            <label for="phone" class="credit-form__label">Мобильный телефон</label>
                            <input type="text" class="credit-form__input" placeholder="(XXX) XXX-XX-XX"
                                   autocomplete="nope" v-model="form.phone" id="phone" name="phone"
                                   @blur="$v.form.phone.$touch()">
                            <div v-if="$v.form.phone.$error" class="invalid-feedback">Пожалуйста, введите телефон в
                                формате +7 (XXX) XXX-XX-XX
                            </div>
                            <ul class="invalid-feedback" v-if="serverErrors">
                                <li v-for="(serverError, index) in serverErrors" :key="index">
                                    <p v-if="serverError.field ==='phone'" >{{serverError.message}}</p>
                                </li>
                            </ul>

                        </div>
                        <div class="credit-form__input-group credit-form__input-group--icon">
                            <label for="email" class="credit-form__label">Электронная почта</label>
                            <input type="email" class="credit-form__input" autocomplete="nope" v-model="form.email"
                                   id="email" name="email" @blur="$v.form.email.$touch()">
                            <div v-if="$v.form.email.$error" class="invalid-feedback">Пожалуйста, введите реальный email
                                адрес
                            </div>
                            <ul class="invalid-feedback" v-if="serverErrors">
                                <li v-for="(serverError, index) in serverErrors" :key="index">
                                    <p v-if="serverError.field ==='email'" >{{serverError.message}}</p>
                                </li>
                            </ul>
                        </div>

                        <div class="credit-form__checkbox-group" :class="{ errorCheck: $v.form.acceptTerms.$error }">
                            <input type="checkbox" id="checkbox" v-model="form.acceptTerms"
                                   class="credit-form__checkbox"
                                   required @blur="$v.form.acceptTerms.$touch()">
                            <span class="checkmark"></span>
                            <label for="checkbox">Я принимаю <a href="/agreement" target="_blank">условия обработки персональных данных.</a></label>
                        </div>
                    </div>
                </div>
            </fieldset>
            <div class="credit-form__btn-wrap">
                <div class="container">
                    <p class="invalid-feedback invalid-feedback--server" v-if="serverErrors">Форма не отправлена!</p>
                    <button type="submit" class="credit-form__btn btn" :disabled="$v.$invalid">
                        <img :src="buttonImg" alt="">Получить деньги
                    </button>
                </div>
            </div>
        </form>
        <Modal v-if="showModal" @close="showModal = false"/>
    </div>
</template>

<script>
    import FormSelect from './Form/Select.vue';
    import Modal from './Modal.vue';
    import {required, email, minLength, maxLength, sameAs, between} from "vuelidate/lib/validators";
    import VueSuggestions from './Form/VueSuggestions.vue';
    import RangeSlider from 'vue-range-slider';
    import Inputmask from "inputmask";

    export default {
        components: {
            RangeSlider,
            FormSelect,
            Modal,
            VueSuggestions
        },

        data() {
            return {
                form: {
                    amount: '1150000',
                    lastName: null,
                    firstName: null,
                    middleName: null,
                    birthDate: null,
                    phone: null,
                    email: null,
                    region: '',
                    city: '',
                    term: {
                        quantity: 3, unit: 'month'
                    },
                    acceptTerms: false
                },
                terms: [
                    {
                        quantity: 3, unit: 'month'
                    },
                    {
                        quantity: 6, unit: 'month'
                    },
                    {
                        quantity: 3, unit: 'year'
                    },
                    {
                        quantity: 1, unit: 'year'
                    },
                    {
                        quantity: 5, unit: 'year'
                    },
                ],
                histories: ['Никогда не брал кредитов', 'Брал кредит'],
                cities: ['Санкт-Петербург', 'Москва'],
                regions: ['Санкт-Петербург', 'Ленинградская область'],
                showModal: false,
                serverErrors: null,
                serverErrorMsg: null,

                //for dadata
                coordinates: {
                    latitude: '',
                    longitude: ''
                },
                regionOptions: {
                    token: '048dadf5d41cf9ab423264ad914baecd1893540c',
                    type: "ADDRESS",
                    hint: false,
                    bounds: "region-area",
                    minChars: 3
                },
                citiesOptions: {
                    token: '048dadf5d41cf9ab423264ad914baecd1893540c',
                    type: "ADDRESS",
                    hint: false,
                    bounds: "city-settlement",
                    minChars: 3,
                    constraints: this.$refs.region
                },
            }
        },
        validations: {
            form: {
                amount: {
                    required,
                    minLength: minLength(5),
                    maxLength: maxLength(7),
                    between: between(10000, 3000000)
                },
                firstName: {
                    required,
                    maxLength: maxLength(25),
                    alpha: val => /^[а-яё\s]*$/i.test(val)
                },
                lastName: {
                    required,
                    maxLength: maxLength(25),
                    alpha: val => /^[а-яё\s]*$/i.test(val)
                },

                middleName: {
                    required,
                    maxLength: maxLength(25),
                    alpha: val => /^[а-яё\s]*$/i.test(val)
                },

                birthDate: {
                    required,
                    maxLength: maxLength(25),
                    validDate: val => /^(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\d\d$/i.test(val)
                },

                phone: {
                    required,
                    maxLength: maxLength(25),
                    validTel: val => /^\+7 \(\d{3}\) \d{3}\-\d{2}\-\d{2}$/i.test(val)
                },
                email: {
                    required,
                    maxLength: maxLength(40),
                    validMail: val => /^[0-9a-z-\.\_]+\@[0-9a-z-\_]{2,}\.[a-z]{2,}$/i.test(val)
                },
                acceptTerms: {
                    sameAs: sameAs( () => true )
                }
            }
        },
        computed: {
            buttonImg() {
                return require("../../../assets/static/money_button.svg");
            },
            strings(value) {
                value = value.toString()
            }
        },

        mounted() {
            const inputs = document.querySelectorAll(".suggestions-input");
            inputs.forEach(input => {
                input.setAttribute("autocomplete", "nope");
            });

            const birthDate = document.getElementById('birthDate');
            const birthMask = new Inputmask("99/99/9999");
            birthMask.mask(birthDate);

            const ph = document.getElementById('phone');
            const phMask = new Inputmask("+7 (999) 999-99-99");
            phMask.mask(ph);
        },

        filters: {
            translates (value, quantity) {
                if (value === 'month') return value.replace('month', 'мес');
                if (quantity.quantity === 1 && value === 'year') return value.replace('year', 'год');
                if ((quantity.quantity > 1) && (quantity.quantity < 5) && value === 'year') return value.replace('year', 'года');
                if (quantity.quantity >= 5 && value === 'year') return value.replace('year', 'лет');
            },

        },

        methods: {
            submitForm() {
                this.form.amount = this.form.amount.toString();
                axios.post('/apply_application', this.form)
                    .then(() => {
                        this.showModal = true;

                        // reset form
                        this.form.amount = '1150000';
                        this.form.lastName = null;
                        this.form.firstName = null;
                        this.form.middleName = null;
                        this.form.birthDate = null;
                        this.form.phone = null;
                        this.form.email = null;
                        this.form.region = '';
                        this.form.city = '';
                        this.form.term.quantity = 3;
                        this.form.term.unit = 'month';
                        this.form.acceptTerms = false;
                        this.serverErrors = null;
                        this.serverErrorMsg = null;

                        this.$v.$reset();

                    }).catch((err) => {
                        this.serverErrors = err.response.data.errors;
                        console.log(this.serverErrors);
                    });
            },
            setSum(value) {
                this.form.amount = value;
                if (this.form.amount > 3000000) this.form.amount = '3000000';
                this.$v.form.amount.$touch();
            }
        },

    }
</script>

