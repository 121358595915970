<template>
	<div>
		<Form />
		<div class="container">
			<List :items="featers" class="list__item--bold"/>
		</div>
		<div class="list-wrap">
			<div class="container">
				<List :items="benefits" />
			</div>
		</div>
	</div>
</template>

<script>
import Form from './components/Form.vue';
import List from './components/List.vue';

export default {

	components: {
		Form,
		List
	},

	data () {
		return {
			featers: [
				{
					text: 'Без поручителей и справок',
					icon: 'static/check.svg'
				},
				{
					text: 'До 3 000 000 рублей',
					icon: 'static/check.svg'
				},
				{
					text: 'Срок до 5 лет, от 8.9% в год',
					icon: 'static/check.svg'
				},
				{
					text: 'Возраст от 18 до 70 лет',
					icon: 'static/check.svg'
				}
			],
			benefits: [
				{
					text: 'Безопасно и конфиденциально',
					icon: 'static/benefits/security.svg'
				},
				{
					text: 'Деньги на любые цели',
					icon: 'static/benefits/money.svg'
				},
				{
					text: 'Нужен только паспорт',
					icon: 'static/benefits/pass.svg'
				},
				{
					text: 'Гибкие условия погашения',
					icon: 'static/benefits/terms.svg'
				}
			]
		}
	}
}
</script>
